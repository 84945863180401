<template>
  <div class="production-track">
    <div class="map-wrapper">
      <div class="opts-area"
           v-if="tableData.length">
        <el-tooltip effect="dark"
                    :content="`${isPlaying ? '暂停' : '播放'}`">
          <i :class="`el-icon-video-${isPlaying ? 'pause' : 'play'}`"
             @click="togglePlay"></i>
        </el-tooltip>
        <el-tooltip effect="dark"
                    content="重置">
          <i class="el-icon-refresh-left"
             @click="reset"></i>
        </el-tooltip>
        <el-tooltip effect="dark"
                    content="导出文件">
          <i class="el-icon-download"
             @click="exportFile"></i>
        </el-tooltip>
      </div>
      <div id="map"></div>
    </div>
    <div class="bottom-table"
         ref="bottom">
      <div class="operations"
           ref="operation">
        <el-form :model="form"
                 inline
                 ref="form">
          <el-form-item label="车牌号码"
                        :rules="{required: true,message:'请选择车辆信息'}"
                        prop="carid">
            <el-select filterable
                       v-model="form.carid">
              <el-option v-for="n in carList"
                         :key="n.carId"
                         :label="n.number"
                         :value="n.carDeviceId"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="开始时间"
                        :rules="{required: true,message:'请选择开始时间'}"
                        prop="beginTime">
            <el-date-picker type="datetime"
                            value-format="yyyyMMddHHmmss"
                            v-model="form.beginTime"></el-date-picker>
          </el-form-item>
          <el-form-item label="结束时间"
                        :rules="{required: true,message:'请选择结束时间'}"
                        prop="endTime">
            <el-date-picker type="datetime"
                            value-format="yyyyMMddHHmmss"
                            v-model="form.endTime"></el-date-picker>
          </el-form-item>
          <!-- <el-form-item>时间间隔
            <el-input min="1" max="30" type="number" v-model="form.interval" style="width:50px;"></el-input>分钟
          </el-form-item>-->
          <el-button type="primary"
                     @click="fetchTableData">查询</el-button>
        </el-form>
      </div>
      <div class="table-area-wrapper">
        <el-table :data="tableData"
                  stripe
                  :border="true"
                  :height="300">
          <el-table-column header-align="center"
                           align="center"
                           v-for="h in headers"
                           :key="h.id"
                           :label="h.label"
                           :prop="h.prop"
                           :width="h.width"
                           :min-width="h.minWidth"
                           :show-overflow-tooltip="h.showTips"
                           :formatter="h.formatter"></el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import axios from 'axios'
import { mapCenter, restfulUrl } from '@/config'
import { download } from '@/utils'

export default {
  name: 'production-track',

  data() {
    const today = dayjs()
    const endTime = today.format('YYYYMMDDHHmmss')
    const beginTime = endTime.substr(0, 8) + '000000'
    return {
      form: {
        func: 1015,
        carid: ' ',
        beginTime,
        endTime,
        // carid: '015919122081',
        // beginTime: '20200417052435',
        // endTime: '20200417060231',
        current: 1,
        size: 20,
      },
      tableData: [],
      total: 0,
      currentIndex: 0,
      headers: [
        { id: 1, label: '序号', prop: 'index', width: 50 },
        {
          id: 2,
          label: '时间',
          prop: 'gpstime',
          width: 180,
          formatter: (row, column, cellValue) => {
            return dayjs(cellValue).format('YYYY-MM-DD HH:mm:ss')
          },
        },
        {
          id: 3,
          label: '位置',
          prop: 'location',
          minWidth: 200,
          showTips: true,
        },
        { id: 4, label: '速度', prop: 'speed', width: 80 },
        { id: 5, label: '状态', prop: 'STATUS', width: 300 },
      ],
      carList: [],
      isPlaying: false,
      lines: [],
      pointIndex: 0,
      showWindow: true,
      downloading: false,
    }
  },

  mounted() {
    this.map = new window.AMap.Map('map', {
      center: mapCenter,
      zoom: 1,
    })
  },

  async created() {
    const data = await this.$http({
      url: this.$http.adornUrl('/production/car/list'),
      method: 'post',
      data: {},
    })
    this.carList = data.datas
  },

  methods: {
    async fetchTableData() {
      this.tableData = []
      this.currentIndex = 0
      this.form.current = 1
      this.reset()
      this.tableTimer && clearTimeout(this.tableTimer)
      this.$refs.form.validate((valid) => {
        if (valid) {
          const start = dayjs(this.form.beginTime)
          const end = dayjs(this.form.endTime)
          if (start > end) {
            return this.$opts.alert('开始时间不能晚于结束时间!')
          }
          if (start.month() !== end.month()) {
            return this.$opts.alert('开始时间与结束时间不能跨月!')
          }
          if (end.diff(start, 'day') > 3) {
            return this.$opts.alert('开始时间与结束时间不能超过3天!')
          }
          axios
            .get(restfulUrl + '/api.json', {
              params: {
                func: 1014,
                carid: this.form.carid,
                beginTime: this.form.beginTime,
                endTime: this.form.endTime,
              },
            })
            .then((res) => {
              this.total = res.data.count
              this.appendTableData()
            })
        }
      })
    },

    appendTableData() {
      axios
        .get(restfulUrl + '/api.json', {
          params: this.form,
        })
        .then((res) => {
          if (res.data.code === 1) {
            this.tableData = this.tableData.concat(
              res.data.list.map((l) => {
                this.setLocation(l, this.currentIndex)
                this.currentIndex++
                return {
                  ...l,
                  index: this.currentIndex,
                  location: '正在解析...',
                }
              })
            )
            this.createTrack()
            if (this.tableData.length < this.total) {
              this.tableTimer = setTimeout(() => {
                this.form.current++
                this.appendTableData()
              }, 2000)
            }
          } else {
            this.$opts.alert(res.data.message)
          }
        })
    },

    setLocation(row, index) {
      this.$http({
        url: this.$http.adornUrl('/api/common/getGeocodeBatch'),
        method: 'post',
        data: [{ longitude: row.lng, latitude: row.lat }],
      }).then((data) => {
        const r = this.tableData[index]
        this.tableData[index] = {
          ...r,
          location: data.datas[0],
        }
      })
    },

    createTrack() {
      this.bezierCurve && this.map.remove(this.bezierCurve)
      const path = []
      for (const point of this.tableData) {
        path.push([[point.lng, point.lat]])
      }
      this.bezierCurve = new window.AMap.BezierCurve({
        path: path,
        strokeWeight: 10,
        strokeColor: 'rgb(112, 138, 199)',
      })

      this.map.add(this.bezierCurve)
    },

    reset() {
      this.clearTimer()
      this.isPlaying = false
      this.pointIndex = 0
      this.removeMark()
      this.infoWindow && this.infoWindow.close()
    },

    togglePlay() {
      this.isPlaying = !this.isPlaying
      this.clearTimer()
      if (this.isPlaying) {
        this.centered = false
        this.timer = setInterval(() => {
          this.updateCarPosition()
        }, 1000)
        this.showWindow = true
        this.updateCarPosition()
      }
    },

    removeMark() {
      if (this.marker) {
        this.map.remove(this.marker)
        this.marker.off('click', this.handleMarkerClick)
      }
    },

    updateCarPosition() {
      if (this.pointIndex > this.tableData.length) {
        return this.clearTimer()
      }
      const AMap = window.AMap
      const currentPoint = this.tableData[this.pointIndex++]
      if (!currentPoint) return
      const coordinates = [currentPoint.lng, currentPoint.lat]
      this.showWindow && this.setWindowContent(currentPoint)
      this.removeMark()
      this.marker = new AMap.Marker({
        position: new AMap.LngLat(currentPoint.lng, currentPoint.lat),
        content: '<img src="/img/state-light-2.png" style="height:24px;">',
        anchor: 'bottom-center',
        offset: new AMap.Pixel(0, 10),
        item: currentPoint,
      })
      this.marker.on('click', this.handleMarkerClick)
      if (!this.centered) {
        this.map.setZoomAndCenter(11, coordinates)
        this.centered = true
      }
      this.map.add(this.marker)
    },

    setWindowContent(data) {
      const AMap = window.AMap
      const coordinates = [data.lng, data.lat]
      const content = `
            <div><label>${data.car_id}</label></div>
            <div><label>时间:</label>${dayjs(data.gpstime).format(
              'YYYY-MM-DD HH:mm:ss'
            )}</div>
            <div><label>速度:</label>${data.speed}</div>
            <div><label>状态:</label>${data.STATUS}</div>
            `
      if (!this.infoWindow) {
        this.infoWindow = new AMap.InfoWindow({
          content,
          anchor: 'bottom-left',
          offset: new AMap.Pixel(10, -10),
        })
        this.infoWindow.on('close', () => {
          this.showWindow = false
        })
      }
      this.infoWindow.open(this.map, coordinates)
      this.infoWindow.setContent(content)
    },

    handleMarkerClick(e) {
      this.showWindow = true
      this.setWindowContent(e.target.w.item)
    },

    clearTimer() {
      this.timer && clearInterval(this.timer)
    },

    exportFile() {
      if (this.downloading) return
      this.downloading = true
      download(
        '/production/carTracks/download',
        {
          carDeviceId: this.form.carid,
          startDate: dayjs(this.form.beginTime).valueOf(),
          endDate: dayjs(this.form.endTime).valueOf(),
        },
        '车辆轨迹信息'
      )
        .then(() => {
          this.downloading = false
        })
        .catch(() => {
          this.downloading = false
        })
    },
  },

  beforeDestroy() {
    this.clearTimer()
  },
}
</script>

<style lang="scss">
.production-track {
  padding: 30px;
  .map-wrapper {
    height: calc(55vh - 20px);
    margin-bottom: 20px;
    min-height: 300px;
    border: 1px solid #ccc;
    border-radius: 4px;
    position: relative;
    #map {
      width: 100%;
      height: 100%;
    }
    .opts-area {
      position: absolute;
      top: 20px;
      right: 20px;
      width: 100px;
      height: 30px;
      background: #fff;
      z-index: 10;
      box-shadow: 0px 2px 4px 0px rgba(66, 66, 66, 0.51);
      border-radius: 2px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      i {
        font-size: 18px;
        cursor: pointer;
        color: #999;
        transition: color 0.2s;
        &:hover {
          font-size: 19px;
          color: #333;
        }
      }
    }
  }
  .bottom-table {
    min-height: 45vh;
    background: #fff;
    box-shadow: 0px 0px 10px 0px rgba(103, 103, 103, 0.1);
    border-radius: 4px;
    padding-bottom: 10px;
    .operations {
      border-bottom: 1px solid #eeeeee;
      padding: 15px 20px;
      .el-form-item {
        margin-right: 30px;
        margin-bottom: 10px;
      }
    }
    .table-area-wrapper {
      margin: 20px;
      border: 1px solid #ccc;
      border-top: 2px solid #c62f2f;
      border-radius: 2px 4px 4px 4px;
      .pager {
        margin: 10px 0;
      }
    }
  }
}
</style>
