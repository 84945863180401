export const mapCenter = [121.41893512085073, 30.960949111064505]
export const videoType = [
  '行驶记录',
  '驾驶员状态',
  '左前支撑腿',
  '右前支撑腿',
  '左后支撑腿',
  '右后支撑腿',
  '固定转塔',
  '末端软管'
]
export const staticUrl = 'https://www.shjcjy.com.cn/files'
export const restfulUrl = 'https://www.shjcjy.com.cn/rest'
export const webSocketUrl = 'wss://www.shjcjy.com.cn/wss'
export const historyFileUrl = 'https://www.shjcjy.com.cn/history'

// export const staticUrl = 'https://pump.sh-cm.com/files'
// export const restfulUrl = 'https://pump.sh-cm.com/rest'
// export const webSocketUrl = 'wss://pump.sh-cm.com/wss'
// export const historyFileUrl = 'https://pump.sh-cm.com/history'

// export const staticUrl = 'http://124.222.179.92/files'
// export const restfulUrl = 'http://124.222.179.92/rest'
// export const webSocketUrl = 'ws://124.222.179.92/ws/'
// export const historyFileUrl = 'http://124.222.179.92/history'
